@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define color gradients */
:root {
  --gradient-primary-light: linear-gradient(135deg, #7AEFFF, #7CFF7A, #FEEC90);
  --gradient-primary-dark: linear-gradient(135deg, rgba(122, 239, 255, 0.15), rgba(124, 255, 122, 0.15), rgba(235, 234, 229, 0.15));
  --gradient-secondary-light: linear-gradient(225deg, #E478FF, #FFC978, #FEEC90);
  --gradient-secondary-dark: linear-gradient(225deg, rgba(228, 120, 255, 0.15), rgba(255, 201, 120, 0.15), rgba(254, 236, 144, 0.15));

  /* Define blurred dots colors */
  --dot-color-1: #7AEFFF;
  --dot-color-2: #7CFF7A;
  --dot-color-3: #FEEC90;
  --dot-color-4: #FFC978;
  --dot-color-5: #E478FF;

  /* Tool Colors */
  --tool-globe-from: rgba(59, 130, 246, 0.1);      /* blue-500/10 */
  --tool-globe-to: rgba(168, 85, 247, 0.1);        /* purple-500/10 */
  --tool-analyser-from: rgba(168, 85, 247, 0.1);   /* purple-500/10 */
  --tool-analyser-to: rgba(236, 72, 153, 0.1);     /* pink-500/10 */
  
  /* Store Colors */
  --store-schemes-from: rgba(99, 102, 241, 0.1);   /* indigo-500/10 */
  --store-schemes-to: rgba(59, 130, 246, 0.1);     /* blue-500/10 */
  --store-documents-from: rgba(6, 182, 212, 0.1);  /* cyan-500/10 */
  --store-documents-to: rgba(20, 184, 166, 0.1);   /* teal-500/10 */
  
  /* Settings Colors */
  --settings-ai-from: rgba(234, 179, 8, 0.1);      /* yellow-500/10 */
  --settings-ai-to: rgba(249, 115, 22, 0.1);       /* orange-500/10 */
  --settings-workspace-from: rgba(16, 185, 129, 0.1); /* emerald-500/10 */
  --settings-workspace-to: rgba(34, 197, 94, 0.1);    /* green-500/10 */
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --svg-color-light: #0A3D91;
    --svg-color-dark: #ffffff;
    --highlighted-bg: 210 100% 97%;
  }

  [data-theme="light"] {
    --svg-color: var(--svg-color-light);
  }
  
  [data-theme="dark"] {
    --svg-color: var(--svg-color-dark);
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --highlighted-bg: 210 30% 20%;
  }

  .hide-footer .footer {
    display: none;
  }
}

@layer base {
  :root {
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

/* Add the bg-highlighted utility class */
@layer utilities {
  .bg-highlighted {
    background-color: hsl(var(--highlighted-bg));
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.d3-overlay {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none; /* Allow map interactions through the SVG */
}

.node {
  cursor: pointer;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.floating-sparkle {
  animation: float 4s ease-in-out infinite;
  opacity: 1;
  font-size: 1.5rem;
}

@keyframes shimmer {
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
}

.animate-shimmer {
  background: linear-gradient(
    45deg,
    theme(colors.red.500) 0%,
    theme(colors.orange.500) 20%,
    theme(colors.yellow.500) 40%,
    theme(colors.green.500) 60%,
    theme(colors.blue.500) 80%,
    theme(colors.violet.500) 100%
  );
  background-size: 400% 400%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 6s ease-in-out infinite;
}

@keyframes shimmer-dynamic {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes shimmerOnce {
  0% { background-position: 200% center; }
  100% { 
    background-position: -200% center;
    /* Keep the final state */
    animation-fill-mode: forwards;
  }
}

.animate-shimmer-once {
  background: linear-gradient(
    to right,
    theme(colors.purple.500) 20%,
    theme(colors.blue.500) 40%,
    theme(colors.blue.400) 60%,
    theme(colors.purple.500) 80%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmerOnce 2s linear forwards;
}

.animate-shimmer-once > div > svg {
  stroke: url(#shimmer) !important;
  fill: none !important;
  stroke-width: 2 !important;
  color: inherit !important;
}

/* Add SVG gradient definition */
svg:has(#shimmer) {
  position: absolute;
  width: 0;
  height: 0;
}

@keyframes shimmer-icon {
  0% { stop-color: theme(colors.purple.500); }
  25% { stop-color: theme(colors.blue.500); }
  50% { stop-color: theme(colors.blue.400); }
  75% { stop-color: theme(colors.purple.500); }
  100% { stop-color: theme(colors.purple.500); }
}

#shimmer stop:nth-child(1) {
  animation: shimmer-icon 2s linear infinite;
}

#shimmer stop:nth-child(2) {
  animation: shimmer-icon 2s linear infinite -0.5s;
}

#shimmer stop:nth-child(3) {
  animation: shimmer-icon 2s linear infinite -1s;
}

@keyframes shimmer-hover {
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
}

/* Add hover shimmer animation class for background */
.hover-shimmer:hover {
  position: relative;
  overflow: hidden;
}

.hover-shimmer:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(239, 68, 68, 0.3) 0%,
    rgba(249, 115, 22, 0.3) 20%,
    rgba(234, 179, 8, 0.3) 40%,
    rgba(34, 197, 94, 0.3) 60%,
    rgba(59, 130, 246, 0.3) 80%,
    rgba(139, 92, 246, 0.3) 100%
  );
  background-size: 400% 400%;
  animation: shimmer-hover s ease-in-out infinite;
  z-index: -1;
}

/* Keep the existing hover shimmer for icons */
.hover-shimmer:hover svg {
  stroke: url(#shimmer) !important;
  fill: none !important;
  stroke-width: 2 !important;
  color: inherit !important;
}

/* Speed up the shimmer animation on hover */
.hover-shimmer:hover #shimmer stop {
  animation-duration: 0.5s !important;
}

/* Add this to your globals.css */
.logo-text-container {
  position: relative;
  overflow: hidden;
  padding-top: 2px; /* Add space between line and text */
}

.logo-text-container .animated-line {
  position: absolute;
  height: 2px;
  background: currentColor;
  opacity: 0.8;
  transform-origin: left;
  top: -6px; /* Moved line up by 4px */
  margin-bottom: 5px;
  animation: drawLogoLine 1s ease-out forwards;
}

/* Reset animation on hover */
.logo-text-container:hover .animated-line {
  animation: none; /* First reset the animation */
  width: 0; /* Reset the width */
  opacity: 0.8;
  animation: drawLogoLine 1s ease-out forwards; /* Then start it again */
}

/* Add animation delay to ensure reset happens */
@media (hover: hover) {
  .logo-text-container:hover .animated-line {
    animation: none;
    width: 0;
    opacity: 0.8;
    animation: drawLogoLine 1s ease-out forwards 0.1s;
  }
}

.logo-text-container .text-highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin-top: -5px;
  animation: highlightLogoText 1s ease-out forwards;
}

/* Light theme gradient - stronger effect */
@media (prefers-color-scheme: light) {
  .logo-text-container .text-highlight {
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(10,61,145,0.25) 15%,
      rgba(10,61,145,0.2) 25%,
      rgba(10,61,145,0.15) 0%,
      transparent 100%
    );
  }
}

/* Dark theme gradient - stronger effect */
@media (prefers-color-scheme: dark) {
  .logo-text-container .text-highlight {
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(255,255,255,0.25) 15%,
      rgba(255,255,255,0.2) 25%,
      rgba(255,255,255,0.15) 40%,
      transparent 100%
    );
  }
}

.logo-text-container:hover .text-highlight {
  animation: highlightLogoText 1s ease-out forwards;
}

@keyframes drawLogoLine {
  0% {
    width: 0;
    transform: translateX(0);
  }
  100% {
    width: 100%;
    transform: translateX(0);
  }
}

@keyframes highlightLogoText {
  0% {
    opacity: 0;
    transform: translateY(-3px);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}